/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../services/toaster.service';
import { responseStatus } from '../constants/responseStatus.const';
  
  @Injectable({
    providedIn: 'root'
  })
export class GetInterceptor implements HttpInterceptor {
  constructor(
      private _toastrService: ToasterService,
      private router: Router,
      private translateService: TranslateService
  ) {}
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // ToDo
          }
        },
        error: (error: HttpErrorResponse) => {
          switch (error['status']) {
          case responseStatus.BADREQUEST: {
            this._toastrService.errToastr(error['error']['message']);
            break;
          }
          case responseStatus.UNAUTHORIZED:
          case responseStatus.FORBIDDEN: {
            this._toastrService.errToastr(error['error']['message']);
            localStorage.clear();
            this.router.navigate(['/']);
            break;
          }
          case responseStatus.REQUESTTIMEOUT: {
            this._toastrService.errToastr(error['error']['message']);
            break;
          }
          case responseStatus.INTERNALSERVERERROR:
            this._toastrService.errToastr(error['error']['message']);
            break;
          case responseStatus.RequestTookMoreThanExpected:
            this._toastrService.errToastr(error['error']['message']);
            break;
          case responseStatus.GatewayTimeOut:
            this._toastrService.errToastr(error['error']['message']);
            break;  
          default:
            this._toastrService.errToastr(
              this.translateService.instant('networkConnection')
            );
          }
        }
      })
    );
  }
}
  