import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@Component({
  selector: 'gitex-lead-web-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    TranslateModule,
    HttpClientModule,
    NgxUiLoaderModule
  ],
  providers: [TranslateService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'apptunix=gitex';

  constructor(
    public translate: TranslateService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          document.body.scrollTop = 0;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 0);
        }
      }
    });
  }
}
