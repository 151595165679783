import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './pages/admin/layout/main/main/main.component';
import { AuthGuardFxn } from './core/guards/auth.guard';
import { MainWebsiteComponent } from './pages/website/layout/main/main-website/main-website.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin/auth',
    pathMatch: 'full'
  },
  {
    path: 'admin/auth',
    canActivate: [AuthGuardFxn],
    loadChildren: () => import('./pages/admin/admin.route').then((m) => m.routes)
  },
  {
    path: 'admin/pages',
    canActivate: [AuthGuardFxn],
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/admin/after_login/after-login.route').then(m => m.routes)
      }
    ]
  },

  {
    path: 'website',
    component: MainWebsiteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/website/website.route').then(m => m.routes)
      }
    ]
  }
];
RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled'
});